import { useEffect } from 'react';
import Head from 'next/head';
import Providers from 'src/core/Providers';
import type { AppProps } from 'next/app';
import theme from 'src/theme/theme';

const MyApp = ({ Component, pageProps }: AppProps) => {
  // This use effect is to keep styles consistency in development
  // https://github.com/mui-org/material-ui/blob/next/examples/nextjs/pages/_app.js
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=1, maximum-scale=4, minimum-scale=1"
        />
      </Head>
      <Providers theme={theme}>
        <>
          <Component {...pageProps} />
        </>
      </Providers>
    </>
  );
};

export default MyApp;
