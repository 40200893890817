import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material';
import palette from './palette';
import typography from './typography';
import components from './components';

const theme: ThemeOptions = {
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  components,
};

export default createTheme(theme);
