import { Components } from '@mui/material/styles/components';
import palette from './palette';
import typography from './typography';
import { FONT_FAMILY } from './typography';

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        WebkitFontSmoothing: 'auto',
        fontSize: '14px',
      },
      body: {
        backgroundColor: palette.grey?.[200],
      },
      'html, body, *': {
        fontFamily: FONT_FAMILY,
      },
      'html, body, #__next': {
        height: '100%',
      },
      // ============= Remove "spin/arrows" from input[type="number"] =============
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        MozAppearance: 'none',
        margin: 0,
      },
      'input[type=number]': {
        MozAppearance: 'textfield',
      },
      a: {
        textDecoration: 'none',
      },
      // change Radio Buttons SVG font-size globally:
      '.MuiRadio-root .MuiSvgIcon-root': {
        fontSize: '1.8rem',
      },
      // '*': {
      //   outline: '1px solid red',
      // },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '0.9285rem',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        // color: palette.grey?.[700],
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      head: {
        fontSize: '0.9285rem', // 13px
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...typography.h3,
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      bar1Determinate: {
        backgroundColor: palette.grey[600],
      },
    },
  },
  // MuiCheckbox: {
  //   styleOverrides: {
  //     root: {
  //       color: palette.grey[300],
  //     },
  //   },
  // },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     root: {
  //       color: palette.grey[400],
  //     },
  //   },
  // },
};

export default components;
