import { createRef, useCallback } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { IconButton, IconButtonProps } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import theme from 'src/theme/theme';
import { Children } from 'src/@types';

const CloseButton = (props: IconButtonProps) => (
  <IconButton aria-label="close" {...props} size="large">
    <CancelOutlinedIcon htmlColor={theme.palette.primary.contrastText} />
  </IconButton>
);

interface NotistackProviderProps {
  children: Children;
}

const NotistackProvider = ({ children }: NotistackProviderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = createRef<any>();
  const onClickDismiss = useCallback(
    (key: SnackbarKey) => (): void => {
      notistackRef.current.closeSnackbar(key);
    },
    [notistackRef],
  );

  return (
    <SnackbarProvider
      action={(snackbarKey: SnackbarKey) => (
        <CloseButton onClick={onClickDismiss(snackbarKey)} />
      )}
      ref={notistackRef}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotistackProvider;
