import { Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './i18n';
import NotistackProvider from './NotistackProvider';
import queryClient from './queryClient';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import datePtBRLocale from 'date-fns/locale/pt-BR';
import './yup';

const Providers = ({ theme, children }: ProvidersProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <NotistackProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={datePtBRLocale}
            >
              {children}
            </LocalizationProvider>
          </NotistackProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

interface ProvidersProps {
  children: ReactElement;
  theme: Theme;
}

export default Providers;
