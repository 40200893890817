import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 3, // 3 hours,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
