const palette = {
  primary: {
    main: '#4cb4de',
    dark: '#2695c2',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#cecece',
    dark: '#bbc0c5',
    contrastText: '#ffffff',
  },
  grey: {
    900: '#252a30',
    700: '#3d454f',
    600: '#515c69',
    500: '#6a798a',
    400: '#a7a7a7',
    300: '#cecece',
    200: '#f0f0f0',
    100: '#cbd5d7',
  },
  text: {
    primary: '#3d454f',
    secondary: '#3d454f',
    disabled: '#909090',
    hint: '#aaa',
  },
  error: {
    main: '#fd6269',
    contrastText: '#fff',
    light: '#ffe3e4',
  },
  warning: {
    main: '#f3af2c',
    contrastText: '#fff',
    light: '#ffeecd',
  },
  success: {
    main: '#23ab5f',
    contrastText: '#fff',
  },
  info: {
    main: '#1ba6e7',
    dark: '#132546',
    contrastText: '#fff',
  },
  // contrastThreshold: 3,
  // tonalOffset: 0.2,
};

export default palette;
