export default {
  translations: {
    global: {
      email: "E-mail cadastrado",
      password: "Senha",
      submit: "Enviar",
      go_back: "Voltar",
      go_login: "Fazer Login",
      or: 'OU',
      title: 'Título',
    },
    professional: {
      MANAGER: 'Manager',
      COPY: 'Copy',
      EXPERT: 'Especialista',
      DESIGN: 'Designer',
      VIDEO: 'Video Maker',
      IT: 'IT / Programação',
      SOCIAL_MEDIA: 'Social Media',
      TRAFFIC: 'Tráfego',
      SUPPORT: 'Suporte',
    },
    task_status: {
      TODO: 'Por fazer',
      DOING: 'Fazendo',
      DONE: 'Pronta',
      BLOCKED: 'Bloqueada',
    },
    task_phase: {
      PPL: 'PPL',
      PL: 'PL',
      L: 'L',
      POSL: 'PÓS-L',
    },
    sidebar: {
      home: 'Home',
      my_launchings: 'Meus Lançamentos',
      create_new_launching: 'Criar Novo Lançamento',
      settings: 'Configurações',
      my_account: 'Minha Conta',
      help: 'Ajuda',
      about: 'Sobre o Lemme',
      logout: 'Sair',
    },
    footer: {
      menu: {
        terms_of_use: "Termos de Uso",
        privacy_politics: "Política de Privacidade",
        help: "Ajuda",
        about: "Sobre",
      }
    },
    login: {
      title: "Faça login usando seu e-mail e senha",
      forgot_password: "Esqueceu a senha?",
      register_btn: "Novo por aqui? Registre-se",
      sign_up: "Assine agora",
      submit_button: "Entrar",
      login_failed: "Login ou senha inválidos."
    },
    forgot_password: {
      title: "Esqueceu a senha?",
      description: "Digite seu e-mail cadastrado para receber as instruções de recuperação da senha.",
      submit_button: "Recuperar Senha",
      back_to_login: "Voltar para o login",
      sent_successfully_title: "E-mail enviado!",
      sent_successfully_description: "Acesse seu e-mail e siga as instruções para recuperar sua senha de acesso.",
      recover_password_failed: "Não foi possível recuperar sua senha"
    },
    recover_password: {
      title: "Troque sua senha",
      description: "Digite abaixo uma nova senha para acessar sua conta",
      email: "Confirme seu e-mail",
      password: "Nova senha",
      password_confirmation: "Confirme nova senha",
      recover_password_failed: "Não foi possível alterar sua senha",
      submit_button: "Alterar Senha",
      success_title: "Senha Alterada"
    },
    register: {
      overline: "Passo {{step}} de {{total}}",
      title: "Informe seus dados",
      your_name: "Nome",
      your_email: "E-mail",
      area_code: "DDD",
      phone_number: "Telefone",
      read_and_agreed: "Li e concordo com os",
      terms_of_use: "Termos de Uso",
      read_and_agreed_and: "e",
      privacy_policy: "Política de Privacidade",
      remember_your_email_will_be: "Lembre-se, o e-mail de acesso será",
      password: "Senha",
      password_confirmation: "Confirme a senha",
      already_have_an_account: "Já possui uma conta?",
      go_to_login: "Acesse",
      register_failed: "Não foi possível concluir seu registro",
      document_type: "Tipo de Documento",
      document_number: "Número do Documento",
      CPF: "CPF",
      passport: "Passaporte",
      date_of_birth: "Data de Nascimento",
    },
    lobby: {
      user_has_guest_profile_title: 'Aguardando liberação',
      user_has_guest_profile_description: 'Olá, {{username}}. Seu usuário está aguardando liberação pela nossa equipe. Não se preocupe, logo tudo estará prontinho para acessar toda nossa plataforma.',
    },
    home: {
      launching_table: {
        headers: {
          name: 'Nome',
          upcoming_tasks: 'Tarefas próximas da entrega',
          delayed_tasks: 'Tarefas em atraso',
          finished_tasks: 'Tarefas feitas',
          percentage: 'Andamento',
        },
        actions: {
          open: 'Abrir',
        },
      },
    },
    dashboard: {
      use_this_template: 'Escolher',
      select_your_launching: 'Selecione um Lançamento',
      choose_your_template: 'Crie um novo Lançamento',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    launchings: {
      phases: {
        PPL: {
          name: 'PPL',
          description: 'Pré pré lançamento, período de início de captação de leads e aquecimento de audiência.',
        },
        PL: {
          name: 'PL',
          description: 'Pré lançamento, evento que antecede a abertura das vendas, aulas, episódios de um evento de lançamento.',
        },
        L: {
          name: 'L',
          description: 'Lançamento, abertura de vendas, abertura de carrinho, recuperação de vendas.',
        },
        POSL: {
          name: 'PÓS-L',
          description: 'Pós lançamento, onboard de novos clientes e "debrifing"',
        },
      },
      see_tasks: 'Ver Tarefas',
      total_tasks: '{{finishedTasks}} de {{totalTasks}} tarefas',
      delayed_tasks: '{{delayedTasks}} tarefas em atraso',
      upcoming_tasks: '{{upcomingTasks}} tarefas perto da entrega',
      start_launching_button: 'Iniciar Lançamento',
      start_launching_description: 'Seu lançamento ainda não começou! Para dar início aos prazos e tarefas do seu lançamento é preciso iniciá-lo.',
    },
    phase_details: {
      delivery_link_placeholder: 'Insira um link para entrega desta task',
      clock_status: {
        delayed: 'Tarefa Atrasada',
        about_to_expire: 'Data de entrega está próxima!',
      }
    },
    create_new_launching_form: {
      name: 'Nome do Lançamento',
      choose_template: 'Escolha o Template'
    },
    create_new_task_form: {
      create_button: 'Nova Task',
      title: 'Criar Nova Tarefa',
    },
    task_details: {
      status: 'Status',
      assignee: 'Responsável',
      group: 'Especialidade',
      slug: 'Slug (URL)',
      delivery_link: 'Link de Entrega',
      starts_at: 'Começa em',
      finishes_at: 'Termina em',
      started_at: 'Começou em',
      finished_at: 'Terminou em',
      submit_btn: 'Salvar',
      delete_btn: 'Remover',
      save_success: 'Task atualizada!',
      save_error: 'Erro na atualização dos dados!',
      phase: 'Fase',
    },
  },
  validations: {
    global: {
      required: "Este campo é obrigatório",
      date_format: "Insira data no formato dd/mm/yyyy",
      minChars: "Insira no mínimo {{min}} caracteres",
      maxChars: "Insira no máximo {{max}} caracteres",
      invalidCPF: "CPF inválido",
      date_must_be_after_start_date: "A data de término deve ser posterior à data de início",
      date_must_be_now_or_before: "A data não pode ser posterior à data atual",
    },
    login: {
      password_shape: "Seu password deve ter: 8 a 22 caracteres, letras maiúsculas, minúsculas, números e um destes símbolos: @$!%*#?&-_",
    },
    register: {
      phone_number_format: "Telefone inválido. Insira apenas números com até 9 dígitos",
      area_code_format: "Código DDD inválido",
      password_shape: "Seu password deve ser formado por letras maiúsculas, minúsculas e números",
      password_doesnt_match: "As senhas informadas não são iguais",
      accept_terms_and_privacy: "Você precisa aceitar os termos de uso e de privacidade",
      invalid_date: "Data inválida. Use o formato dd/mm/aaaa.",
      underage: "Menor de idade? Entre em contato conosco que vamos te ajudar!",
      overage: "Wow! Tem mais do que {{max}} anos? Entre em contato e vamos lhe ajudar!",
    },
    create_launching: {
      id: 'O lançamento é um campo obrigatório',
    },
    create_launching_template: {
      id: 'O template do lançamento é um campo obrigatório',
    },
    task_crud: {
      launching_id: 'O lançamento é um campo obrigatório',
      phase_id: 'A fase da tarefa é um campo obrigatório',
      status_id: 'A status da tarefa é um campo obrigatório',
      group_id: 'A grupo da tarefa é um campo obrigatório',
      status: 'O status da tarefa deve ser informado',
    },
  }
}
