import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import ptResources from 'src/config/locales/pt';

i18n.use(initReactI18next).init({
  lng: 'pt',
  fallbackLng: 'pt',
  debug: false,
  ns: ['translations', 'validations', 'currencies'],
  defaultNS: 'translations',
  fallbackNS: 'translations',
  react: {
    bindI18n: 'languageChanged loaded',
    nsMode: 'fallback',
  },
  resources: {
    pt: ptResources,
  },
});

export default i18n;
