import { TypographyOptions } from '@mui/material/styles/createTypography';

export const FONT_FAMILY = "'Roboto', Helvetica, Arial, sans-serif";

/*
Base 14:

10px: 0.7143rem
12px: 0.8571rem
13px: 0.9285rem
14px: 1rem
16px: 1.1428rem
18px: 1.2857rem
24px: 1.7142rem
26px: 1.8571rem
*/
const typography: TypographyOptions = {
  htmlFontSize: 10,
  fontFamily: FONT_FAMILY,
  h1: {
    fontSize: '1.8571',
  },
  h2: {
    fontSize: '1.7142rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.2857rem',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1.1428rem',
    fontWeight: 500,
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: '0.8571rem',
  },
  subtitle2: {
    fontSize: '0.7143rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.9285rem',
    textTransform: 'uppercase',
  },
  overline: {
    fontSize: '0.7143rem',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.7143rem',
  },
};

export default typography;
